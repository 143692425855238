import { graphql, Link } from "gatsby"
import styled from "styled-components"
import React, { Component } from "react"
import "@babel/polyfill"

import SectionHero from "../components/section-hero";
import Layout from "../layouts"
import Head from "../components/head"

import '../css/country-selector.css';

import YoastSeoParts from '../fragments/seo';

class CountrySelectorTemplate extends Component {

  render() {
    const { page, countries } = this.props.data;
    const { seo } = page
    const { map, ataTitle, ataCopy, pageAccentColor} = page.template.templateCountrySelector;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <SectionHero section="resource" title={page.title}/>

        <div className="container copyFullLeft">
          <div className="grid smallGrid">
            <div className="col-sm-12 maincopy">

              <h2 style={{ padding: 0 }}>Select a Country:</h2>
              <hr style={{ borderColor: pageAccentColor || '#fe6a00' }} />

              <img src={map.localFile.publicURL} alt={map.altText} style={{ width: '100%' }} />
            </div>
          </div>
        </div>

        <div className="container countries-copy-wrapper" style={{ paddingTop: '20px' }}>
          <div className="fullWidhtContainer">
            <div className="grid smallGrid">
              <h2>{ataTitle}</h2>
              <div className="col-sm-12 maincopy" dangerouslySetInnerHTML={{ __html: ataCopy }} />
            </div>
          </div>
        </div>

        <div className="container countries-list-wrapper">
	        <div className="grid smallGrid align-center">
            <div className="col-md-12 maincopy">
              <ul>
                {countries && countries.nodes.map(country => {
                  if (!country.cptCountries.country) {
                    return;
                  }

                  const { country: countryName, abbreviation } = country.cptCountries;

                  return (
                    <li key={countryName}><Link to={country.uri}>{countryName} {abbreviation && `(${abbreviation})`}</Link></li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default CountrySelectorTemplate

export const query = graphql`
  query($id: String!) {
    page: wpPage(id: {eq: $id}) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_CountrySelector {
          templateName
          templateCountrySelector {
            ataCopy
            ataTitle
            map {
              altText
              localFile {
                publicURL
              }
            }
            pageAccentColor
          }
        }
      }
    }

    countries: allWpCountry(sort: {order: ASC, fields: title}) {
      nodes {
        uri
        title
        cptCountries {
          abbreviation
          country
        }
      }
    }
  }
`